var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "search-page"
  }, [_c('div', {
    staticClass: "search-page__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": "Поиск"
    }
  })], 1), _vm.searchResults && _vm.searchResults.length ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "search-page__table"
  }, [_c('div', {
    staticClass: "search-results"
  }, [_c('div', {
    staticClass: "search-results__view"
  }, [_c('div', {
    staticClass: "search-results__list"
  }, _vm._l(_vm.searchResults, function (item, index) {
    return _c('a', {
      key: index,
      staticClass: "search-results__item",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.goToResource($event, item.tablename, item.id);
        }
      }
    }, [_c('i', {
      staticClass: "search-results__icon",
      class: {
        'search-results__icon_users': item.tablename === 'users',
        'search-results__icon_companies': item.tablename === 'companies',
        'search-results__icon_projects': item.tablename === 'projects'
      }
    }), item.name ? _c('span', {
      staticClass: "search-results__title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e(), item.tablename ? _c('span', {
      staticClass: "search-results__descr"
    }, [_vm._v(" " + _vm._s(item.category) + " ")]) : _vm._e()]);
  }), 0)])])]) : _vm._e(), _vm.searchResults && _vm.searchResults.length ? _c('div', {
    staticClass: "search-page__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.getResults
    }
  })], 1) : _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "search-results-no"
  }, [_vm._v(" Нет результатов ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }